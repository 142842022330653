.web-share-fade {
  animation: rwsFade 0.5s;
  animation-fill-mode: both;
}

@keyframes rwsFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.web-share-fade-in-up {
  animation: rwsfadeInUp 0.5s;
  animation-fill-mode: both;
}

@keyframes rwsfadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rws-icons {
  padding: 1.25rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.25rem;
}

.rws-icon {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 0;
  background: #1a78f6;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-size: 0;
}

.rws-container {
  max-width: 24rem;
  width: 90%;
  background-color: white;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rws-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1400;
}

.rws-header {
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 0;
}

.rws-close {
  background: #edf2f7;
  cursor: pointer;
  padding: 0.75rem;
  display: block;
  width: 100%;
  border: 0;
  font-size: 1rem;
}
