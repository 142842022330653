/*@import '~antd/dist/antd.less';*/
@import '/src/styles/inmo/inmo.less';
@import '/src/styles/loans/Loans.less';
@import '/src/styles/skeleton.less';
@import '/src/styles/basic/basic.less';
@import '/src/styles/Certificate/Certificate.less';
@import '/src/styles/help/help.less';
@import '/src/styles/promoter-loan/promoter.less';
@import '/src/styles/onboarding/onboarding.less';
@import '/src/styles/calculator/calculator.less';
@import '/src/styles/inmo/propertyAppaisal.less';
@import '/src/styles/documents/documents-new.less';

body {
	background: #f0f2f5;
	font-family: 'Inter';
}

.ant-modal .ant-modal-content {
	padding: 0px !important;
	font-family: 'Inter';
}
.ant-modal-close:hover {
	background-color: #fff !important;
}

.ant-typography {
	font-family: 'Inter';
}

label {
	font-family: 'Inter';
}

span {
	font-family: 'Inter';
}

.ant-tabs-tab {
	font-family: 'Inter' !important;
}

.ant-slider-rail {
	background-color: #c0daee !important;
}

.ant-slider-handle::after {
	box-shadow: 0 0 0 2px #02c3cd !important;
}

.ant-select-arrow {
	margin-top: 0px !important;
}

.ant-select-selector {
	font-family: 'Inter';
}

.card-init {
	.ant-card {
		line-height: 1.5715;
	}
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.1s;
}

.trigger:hover {
	color: #1890ff;
}

.antd-pro-charts-pie-legend {
	align-self: center;
}

.logo {
	width: 100%;
	padding: 35px 35px;
	background-color: white;
	overflow: hidden;
}

.logo img {
	width: 100%;
}

.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-text h4 {
	margin: 8px;
	font-family: 'Inter';
}

.ant-layout-sider-collapsed .logo {
	padding: 7px;
}

.ant-menu-item {
	width: 100% !important;
	border-radius: 0px !important;
	margin-inline: 0px !important;
}

.ant-menu-inline-collapsed > .ant-menu-item-selected {
	border-right: 3px solid #02c3cd;
	width: 100% !important;
}

.lang-avatar {
	background-color: @primary-color !important;
}

.ant-menu-inline-collapsed {
	width: 56px;
}

.ant-card {
	.h1-tabpane {
		background-color: #fff;
	}
}

.ant-form label {
	font-size: 10px;
}

.ant-collapse > .ant-collapse-item {
	border-bottom: 1px solid #f1f7f8;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	background: #c0daee;
	text-shadow: none;
	box-shadow: none;
}

.ant-form-item-explain.ant-form-item-explain-error {
	font-size: 10px;
}

.ant-input-number {
	border-color: #c0daee;
	width: 100%;
	border-radius: 4px;
}

.ant-input-number:hover {
	border-color: #02c3cd !important;
}

.afrodita.gb-input-number:hover {
	border-color: #02c3cd !important;
}

.ant-input {
	height: 32px;
	border-radius: 4px;
	border-color: #c0daee;
	font-size: 12px;
	width: 280px;
}

.ant-input:hover {
	border-color: #02c3cd;
}

.ant-input-number-input {
	height: 40px;
	padding: 0 11px;
	text-align: left;
	font-size: 14px;
	outline: 0;
}

.ant-picker-input > input {
	font-size: 12px;
}

.ant-card-head-title {
	font-size: 12px;
	font-weight: 500;
}

.fc-time-grid .fc-content-skeleton {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	right: 0;
	height: 100% !important;
}

.elementList {
	padding: 16px 24px !important;
	color: #f0f0f0 !important;
	margin: 4px 12px !important;
	border-radius: 4px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: rgba(250, 254, 254);
	font-weight: bolder;
	border-right: 3px solid #02c3cd;
	border-radius: 0px;
	width: 99%;
}

.small-font-size {
	font-size: 80%;

	select,
	span {
		font-size: 80%;
	}
}

.documents-list-row-completed {
	background-color: #d7e2d5;
}

.documents-list-row-error {
	background-color: #db8a6a;
}

.operations-data-page {
	.ant-card:hover {
		background-color: #fafafa;
		z-index: 1;
	}

	.ant-card-head {
		background: #fafafa;
	}
}

.g-header {
	padding: 0 10px;
}

.g-submenu {
	height: 75px;
}

.ant-layout {
	overflow-x: hidden;
	overflow-y: hidden;
}

.ant-menu-item {
	padding: 0;
}

.g-ant-menu-item {
	padding: 0;
}

.ant-menu-submenu-title {
	height: 56px !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.ant-input-group > .ant-input:first-child {
	background-color: #f4f4f4;
}

.ant-steps-item-finish .ant-steps-item-icon {
	border-width: 2.5px;
	background: #c0f0f3;
	border-color: #02c3cd !important;
}

.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title {
	color: #748ea0;
	margin-top: -4px;
	font-size: 12px;
	font-weight: normal;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
	background-color: #02c3cd;
}

.barraStyle {
	height: 350px;
	overflow-y: scroll;
}

::-webkit-scrollbar {
	width: 12px;
	background-color: #f3f4f8;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	height: 50px;
	background-color: #bfbfbf;
}

.ant-steps-small .ant-steps-item-title {
	padding-right: 12px;
	font-size: 11px;
	line-height: 24px;
}

.ant-progress-circle-trail {
	stroke: #c7ccd8;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 4px;
	border-color: #c0daee;
	font-size: 12px;
	height: 40px;
}

.ant-select-selector:hover {
	border-color: #02c3cd !important;
}

.ant-slider-handle {
	border-color: #02c3cd;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
	background: rgba(154, 204, 216, 0.07);
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
	background-color: white;
	border-bottom-color: rgba(154, 204, 216, 0.15);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
	background: rgba(154, 204, 216, 0.07);
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
	background-color: white !important;
	border-bottom-color: rgba(154, 204, 216, 0.15);
}

.ant-steps-item-error .ant-steps-item-icon {
	border-color: #02c3cd !important;
	border-width: 2.5px;
	//background-image: linear-gradient(to right top, #c7ccd8, #ccd8e4);
	background-color: white !important;
}

.ant-steps-item-error
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title {
	color: #02c3cd !important;
	font-size: 12px;
	margin-top: -4px;
	font-weight: 600;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
	display: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	display: none;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
	display: none;
}

.ant-steps-item-wait .ant-steps-item-icon {
	border: 2px solid #c0daee !important;
	box-sizing: border-box;
	background: #ffffff;
}

.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th {
	background: white;
}

.ant-table.ant-table-bordered > .ant-table-container {
	border: 1px solid white;
}

.ant-progress-circle-trail {
	stroke: '#C0F0F3';
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
	background-color: white;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
	background-color: white;
	border: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
	visibility: initial;
}

.ant-btn:empty {
	visibility: initial;
}

.gibobs-document-button-overlay {
	opacity: 0;
	transition: opacity 0.3s ease-out;
	-webkit-transition: opacity 0.3s ease-out;
}

.gibobs-document-button:hover {
	.gibobs-document-button-overlay {
		opacity: 0.9;
	}
}

.ant-progress-circle-trail {
	stroke: #c0f0f3;
}

.ant-progress-circle .ant-progress-text {
	color: #02c3cd;
	font-size: 24px;
	line-height: 100%;
	font-weight: bold;
}

.StepsAppraisal {
	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
		background: white;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			.ant-steps-icon {
				display: inline-flex;
			}
		}
	}

	.ant-steps-item-tail::after {
		margin-left: 4px;
		margin-top: 15px;
		width: 2px;
		height: 100%;
	}
}

.StepsAppraisal2 {
	.ant-steps-item-icon {
		width: 32px !important;
		height: 32px !important;
		background: white;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			.ant-steps-icon {
				display: inline-flex;
			}
		}
	}

	.ant-steps-item-tail::after {
		margin-left: 4px;
		margin-top: 15px;
		width: 2px;
		height: 100%;
	}

	.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
		width: 16px;
		height: 16px;
	}

	.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
		margin-bottom: -10px;
	}

	.ant-steps-vertical
		> .ant-steps-item:not(:last-child)
		> .ant-steps-item-container
		> .ant-steps-item-tail {
		margin-left: -5px;
		min-height: 60px;
		margin-top: -14px;
	}
}

.ant-table-tbody {
	font-size: 12px;
}

.ant-table-content {
	margin-left: -12px;
}

/******* COMMON *********/

.gibobs-breadcrumb {
	margin: 24px 40px;

	.ant-breadcrumb-link {
		color: #748ea0;
		font-size: 12px;
	}

	& > span:last-child .ant-typography {
		color: #02c3cd !important;
		font-weight: bold;
	}
}

.spin {
	color: #02c3cd;
	display: flex;
	justify-content: center;
	margin-top: 48px;
	margin-bottom: 40px;
}

.toolTipProperty {
	height: 32px;
	font-size: 12px;
	border-radius: 4px;
}

.popOverProperty {
	height: 81px;
	width: 100px;
	font-size: 12px;
	padding-left: 0;
	padding-right: 0;
}

/*.button-primari-gibobs {
  background-color: #02c3cd;
  border-radius: 4px;
  color: #ffff;
  width: 100;
  height: 24;
  font-size: 14px;
  text-shadow: none;
  box-shadow: none;
}*/
.button-primari-gibobsNew {
	background-color: #02c3cd !important;
	border-radius: 4px;
	color: #ffff;
	width: 100%;
	height: 24;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
}

// .button-primari-gibobs-promotor {
//   background-color: #124887;
//   border-radius: 4px;
//   color: #ffff;
//   width: 100;
//   height: 24;
//   font-size: 14px;
//   text-shadow: none;
//   box-shadow: none;
// }
.ant-btn-primary[disabled] {
	color: #fff;
	border: none;
}

/*.button-secundary-gibobs {
  border-color: #02c3cd;
  border-radius: 4px;
  color: #02c3cd;
  width: 120;
  height: 32;
  font-size: 14px;
  font-weight: 500;
}*/

.styleInput {
	border: 1px solid #c0daee;
	height: 32px;
	border-radius: 4px;
	padding-top: 7px;
	padding-left: 10px;
	color: #748ea0;
}

.ModalCommon {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 40px;
		padding-top: 40px;
		padding-bottom: 38px;
		padding-right: 40px;
		border-radius: 4px;
	}

	.ant-modal-body {
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 0px;
		border-radius: 4px;
		//padding-bottom: 40px
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 40px;
		padding-bottom: 40px;
		padding-left: 40px;
		border-top: none;
		border-radius: 4px;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 22px;
		padding-right: 38px;
	}
}

.ModalAmigobs {
	.ant-modal-close {
		padding-right: 20px;
	}
	.ant-modal-close:hover {
		display: none;
	}

	.buttonLink {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		background-color: #0088c6;
		color: #fff;
		width: 128px;
		height: 40px;
		margin-top: 16px;
	}

	.copyLink {
		background-color: #fff;
		padding: 18px;
		width: 454px;
		height: auto;
		border-radius: 9px;
		margin-top: 30px;
		display: flex;
		align-items: center;
	}
}

.ModalCommonConfirm {
	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 32px;
	}

	.ant-btn {
		color: #02c3cd;
		width: 96px;
	}

	.ant-btn:hover,
	.ant-btn:focus {
		color: #41d2da;
		background: #fff;
		border-color: #41d2da;
	}

	.ant-btn-dangerous {
		color: #ffffff;
		width: 96px;
		background-color: #ff4d4f;
		border: #ff4d4f;
		text-shadow: none;
		box-shadow: none;
	}

	.ant-btn-dangerous:hover,
	.ant-btn-dangerous:focus {
		color: #fff;
		background: #ff7875;
		border-color: #ff7875;
	}

	.ant-modal-confirm-title {
		font-size: 14px;
	}

	.ant-modal-confirm-content {
		font-size: 12px;
		margin-top: 16px;
	}
}

.ModalCommonConfirmPaid {
	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 32px;
	}

	.ant-modal-confirm-title {
		font-size: 14px;
	}

	.ant-modal-confirm-content {
		font-size: 12px;
		margin-top: 16px;
	}
}

.ModalCommonSmall {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 38px;
		padding-right: 32px;
		border-radius: 4px;
	}

	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 0px;
		font-size: 12px;
		border-radius: 4px;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 32px;
		padding-left: 20px;
		padding-bottom: 32px;
		border-top: none;
		border-radius: 4px;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 16px;
		padding-right: 28px;
	}
}

.ModalCommonSmallIBAN {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
		width: 384px;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 38px;
		padding-right: 32px;
	}

	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 0px;
		font-size: 12px;
		width: 384px;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 32px;
		padding-bottom: 32px;
		border-top: none;
		width: 384px;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 12px;
		padding-right: 28px;
	}
}

.ModalCommonSmallDelete {
	.ant-modal-header {
		padding-bottom: 16px;
	}

	.ant-modal-footer {
		text-align: center !important;
	}
}

.ModalKnowMore {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
	}

	.ant-modal-body {
		color: '#748EA0';
		padding-left: 32px;
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 14px;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-bottom: 32px;
		border-top: none;
		text-align: center;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 16px;
		padding-right: 28px;
	}
}

.ModalConfirmOffer {
	.ant-modal-content {
		max-width: 480px;
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
		padding-right: 16px;
		max-width: 90%;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 24px;
		border-radius: 4px;
	}

	.ant-modal-body {
		font-size: 14px;
		font-weight: 400;
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 0px;
		border-radius: 4px;
	}

	.ant-modal-footer {
		width: 100%;
		padding-top: 0;
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 32px;
		border-top: none;
		border-radius: 4px;
		display: flex;
		gap: 8;
		justify-content: center;
	}

	.ant-btn {
		width: 48%;
		height: 40px;
		border-radius: 4px;
	}

	.ant-btn-default {
		border-color: #02c3cd;
		color: #02c3cd;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 17px;
		padding-right: 32px;
	}
}

.ModalTableOffers {
	.ant-modal-body {
		padding: 0px;
	}
}

.ModalAnalyst {
	.ant-modal-content {
		border-radius: 8px;
	}
	.ant-modal-body {
		padding: 24px !important;
	}
}

.showMoreSlide {
	&-enter {
		opacity: 0;
		transform: translateY(30%);

		&-active {
			opacity: 1;
			transform: translateY(0%);
			transition: opacity 400ms, transform 200ms;
		}
	}

	&-exit {
		opacity: 1;
		transform: translateY(0%);

		&-active {
			opacity: 0;
			transform: translateY(30%);
			transition: opacity 200ms, transform 400ms;
		}
	}
}

.InputPassWord {
	background: #ffffff;
	border: 1px solid #c0daee;
	box-sizing: border-box;
	border-radius: 4px;
	height: 42px;

	.ant-input {
		height: 12px;
		align-self: center;
	}
}

.buttonAttach {
	width: 104px;
	height: 104px;
	background-color: white;
	border-radius: 4px;
	margin-left: -15px;
	border-color: red;
}

.CardViability {
	.ant-card-body {
		padding: 24px;
	}
}

.EstimatedCards {
	margin-right: 16px;
}

.CardViabilityAjustFlex {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 24px;
	// gap: 1em;
}

.CardViabilityAjustFlex > *:nth-child(1) {
	flex: 1 1 250px;
	// width: 250px;
}

.CardViabilityAjustFlex > *:nth-child(2) {
	flex: 1 1 65%;
	// min-width: 520px;
	margin-left: 24px;
}

.cardStyleLoan {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.cardStyleLoan > *:nth-child(1) {
	flex: 1 1 20%;
	min-width: 250px;
	margin-right: 40px;
}

.cardStyleLoan > *:nth-child(2) {
	flex: 1 1 20%;
	// min-width: 520px;
	// margin-left: 24px;
}

.inputResponsive {
	// width: 250px;
	min-width: 223px;
}

.basicDataComponent {
	.ant-collapse-content-box {
		padding: 32px;
	}
}

.radioButtonMortgage {
	display: flex;
	gap: 10%;
	flex-wrap: wrap;
	justify-content: center;
}

.mortgageInfo {
	display: flex;
	flex-direction: row;
}

.subrogationInfo {
	display: flex;
	flex-direction: row;
	// align-self: center;
}

.contentImageAndTitleMortgage {
	margin-right: 36px;
	flex-direction: row;
	display: flex;
	align-self: center;
}

.Intento2Reajuste {
	width: auto;
	display: flex;
	flex-wrap: wrap;
	gap: 4%;
}

.Intento2Reajuste > * {
	flex: 1 1 20%;
}

.breadCrumb {
	margin: 16px 40px;
	width: 100%;
	margin-bottom: 20px;
}

.breadCrumbViability {
	margin-left: 40px;
}

.cardTypeViabilityMobile {
	width: 200px;
	height: auto;
	margin-top: 16px;
	border: none;
}

.cardAjustRates {
	display: flex;
}

@media only screen and (min-width: 1024px) {
	.inputResponsive {
		width: 22%;
	}

	.headerMovil {
		display: none;
	}

	.headerDesktop {
		display: flex;
	}
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
	.inputResponsive {
		width: 30%;
	}

	.mortgageInfo {
		flex-direction: column;
		margin-bottom: 36px;
	}

	.subrogationInfo {
		flex-direction: column;
	}

	.radioButtonMortgage {
		gap: 10%;
	}

	.contentImageAndTitleMortgage {
		margin-right: 0;
		margin-bottom: 20px;
	}

	.breadCrumb {
		margin-left: 24px;
	}
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
	.inputResponsive {
		width: 40%;
	}

	.basicDataComponent {
		.ant-collapse-content-box {
			padding: 24px;
		}
	}

	.mortgageInfo {
		flex-direction: column;
		margin-bottom: 36px;
	}

	.subrogationInfo {
		flex-direction: column;
	}

	.radioButtonMortgage {
		gap: 0;
	}

	.contentImageAndTitleMortgage {
		margin-right: 0;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.radioHaveDecide {
		align-self: center;
	}

	.breadCrumb {
		margin-left: 16px;
	}
}

@media only screen and (max-width: 360px) {
	.inputResponsive {
		width: 100%;
	}

	.basicDataComponent {
		.ant-collapse-content-box {
			padding: 24px;
		}
	}

	.mortgageInfo {
		flex-direction: column;
		margin-bottom: 36px;
	}

	.subrogationInfo {
		flex-direction: column;
	}

	.radioButtonMortgage {
		gap: 0;
	}

	.contentImageAndTitleMortgage {
		margin-right: 0;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.radioHaveDecide {
		align-self: center;
	}

	.breadCrumb {
		margin-left: 16px;
	}
}

@media only screen and (max-width: 750px) {
	.CardViabilityAjustFlex > *:nth-child(2) {
		flex: 1 1 65%;
		margin-left: 0;
		width: 100%;

		margin-top: 32px;
	}
}

.iconOffersTable {
	font-size: 16px;
	margin-right: 5px;
	color: #2f4858;
}

.iconOffersTable:hover {
	color: #02c3cd;
}

/******* END COMMON *********/

/******* MY DOCUMENTS *********/

@documentWidth: 104px;
@documentTrasition: 0.3s;

.documents {
	.title-label {
		font-size: 14px;
		color: #2f4858;
		font-weight: 600;
	}

	.document-container {
		overflow: hidden;
		align-items: flex-start;
		padding: 0px;
		margin-top: 25px;
		margin-bottom: 40px;

		.document-item {
			position: relative;
			overflow: hidden;
			display: block;
			float: left;
			width: @documentWidth;
			height: @documentWidth;
			border-radius: 4px;
			margin: 10px;
			text-align: center;
			transition: all @documentTrasition;

			.document-content {
				position: absolute;
				top: 0;
				left: 0;
				width: @documentWidth;
				height: @documentWidth;
			}

			&.folder {
				background: #2f4858;
				color: #fff;
			}

			&.file {
				color: #748ea0;
				background: #fff;
				border: 1px solid #c0daee;
			}

			&.add-folder,
			&.add-file {
				cursor: pointer;
			}

			.icon {
				margin-top: 20px;
				font-size: 18px;
				transition: all @documentTrasition;
			}

			.add {
				font-size: 25px;
				color: @primary-color;
			}

			.title {
				width: @documentWidth;
				height: 50px;
				white-space: pre-wrap;
				padding: 5px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				line-height: 18px;
				font-size: 14px;
				transition: all @documentTrasition;
			}

			.overlay {
				color: #2f4858;
				border-radius: 4px;
				opacity: 0;
				position: absolute;
				width: @documentWidth;
				height: @documentWidth;
				transition: all @documentTrasition;
				line-height: @documentWidth;
				z-index: 0;

				.mini-button {
					cursor: pointer;
				}
			}

			&.clickable {
				cursor: pointer;
			}

			.document-menu {
				position: absolute;
				top: 5px;
				left: 87px;
				z-index: 1;
			}
		}

		.document-item.overable:hover {
			background-color: fade(@primary-color, 50%);

			.icon,
			.title {
				opacity: 0;
			}

			.overlay {
				opacity: 1;
				z-index: 1;
			}
		}
	}

	.document-container-mode-list {
		padding: 0px;
		margin-top: 45px;
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;

		.title {
			width: @documentWidth;
			height: 30px;
			white-space: pre-wrap;
			padding: 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			line-height: 18px;
			font-size: 12px;
			transition: all @documentTrasition;
			margin-left: 30px;
			color: #2f4858;
			position: relative;
			margin-top: -23px;
			font-weight: 400;
		}

		.titleFolder {
			width: @documentWidth;
			height: 30px;
			white-space: pre-wrap;
			padding: 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			line-height: 18px;
			font-size: 12px;
			transition: all @documentTrasition;
			color: #2f4858;
			position: relative;
			margin-top: -180px;
			margin-left: 4px;
			font-weight: 600;
		}

		.document-item {
			position: relative;
			display: block;
			float: left;
			width: 30px;
			height: 30px;
			border-radius: 4px;
			margin: 10px;
			text-align: center;
			transition: all @documentTrasition;

			.document-content {
				position: absolute;
				top: 0;
				left: 0;
				width: 30px;
				height: 30px;
			}

			&.folder {
				color: #2f4858;
			}

			&.file {
				color: #748ea0;
				background: #fff;
				border: none;
			}

			&.add-folder,
			&.add-file {
				cursor: pointer;
			}

			.icon {
				// margin-top: 10px;
				font-size: 14px;
				transition: all @documentTrasition;
			}

			.add {
				margin-left: -5px;
				font-size: 16px;
				color: @primary-color;
			}

			.overlay {
				color: #2f4858;
				border-radius: 4px;
				opacity: 0;
				position: absolute;
				width: @documentWidth;
				height: @documentWidth;
				transition: all @documentTrasition;
				line-height: @documentWidth;
				z-index: 0;

				.mini-button {
					cursor: pointer;
				}
			}

			&.clickable {
				cursor: pointer;
			}

			.document-menu {
				position: absolute;
				top: 5px;
				margin-left: 587px;
				color: #2f4858;
			}
		}
	}

	.gcard.tips {
		text-align: center;
		font-size: 12px;
		font-weight: 400;

		.tip-p {
			margin-top: 32px;
			color: #748ea0;

			img {
				margin-bottom: 26px;
			}
		}
	}
}

/******* END MY DOCUMENTS *********/

.BarraProgress {
	width: 200px;
}

.antd-pro-charts-pie-chart {
	width: 200px;
}

// .ant-modal-footer {
//   padding-top: 0;
//   padding-right: 32px;
//   padding-bottom: 32px;
// }

// .antd-pro-charts-pie-legend ::marker {
//   color: #02c3cd;
// }
// // .antd-pro-charts-pie-legend ::marker {
//   color: red;
// }
.antd-pro-charts-pie-legend li {
	margin-bottom: 24px;
}

.antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend .antd-pro-charts-pie-chart {
	width: 140px;
}

.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
	position: relative;
	top: 30%;
	text-align: justify;
}

.antd-pro-charts-pie-percent {
	color: #748ea0;
}

.antd-pro-charts-pie-value {
	color: #02c3cd;
}

.antd-pro-charts-pie-legend {
	color: #2f4858;
	font-weight: 500;
	font-size: 12px;
	line-height: 100%;
	margin-top: 20px;
}

.ant-modal-wrap .ScoringModal {
	padding-left: 40px;

	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 16px;
		border-bottom: none;
	}

	.ant-modal-body {
		padding-bottom: 40px;
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 0px;

		.ant-typography {
			color: #748ea0;
		}
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 14px;
		padding-right: 28px;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 32px;
		padding-bottom: 32px;
		border-top: none;
	}
}

.SubMenuOperaciones {
	.ant-menu-sub {
		height: 150px;
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

.card-second {
	.ant-card-body {
		padding: 16px;
	}
}

/******* CHARTS *********/

.PieSaving {
	.antd-pro-charts-pie-value {
		display: none;
	}

	.antd-pro-charts-pie-legend {
		margin-top: 10px;
		top: 15%;
	}
}

.PieClosedExpense {
	display: grid;
	grid-template-columns: 0.5fr 5fr;
	width: 200px;
	margin-top: 0;

	.antd-pro-charts-pie-legend {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		top: 30%;
	}

	.antd-pro-charts-pie-value {
		position: inherit;
		margin-left: 10px;
		margin-right: 10px;
		float: right;
	}
}

.PieClosedActive {
	display: grid;
	grid-template-columns: 0.5fr 5fr;
	width: 400px;
	margin-top: 30px;

	.antd-pro-charts-pie-legend {
		top: 30%;
	}

	.antd-pro-charts-pie-value {
		position: inherit;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.PieDashboard {
	display: grid;
	grid-template-columns: 0.5fr 5fr;
	width: 200px;

	.antd-pro-charts-pie-legend {
		top: 30%;
	}

	.antd-pro-charts-pie-value {
		position: inherit;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.PieClosedIncome {
	display: grid;
	grid-template-columns: 0.5fr 2fr;
	width: 200px;
	margin-top: 0;

	.antd-pro-charts-pie-value {
		position: inherit;
		margin-left: 10px;
		float: right;
	}
}

.PieClosedActive {
	display: grid;
	grid-template-columns: 0.5fr 5fr;
	width: 200px;
	margin-top: 0px;

	.antd-pro-charts-pie-legend {
		margin-top: 20px;
	}

	.antd-pro-charts-pie-value {
		position: inherit;
		margin-left: 10px;
		margin-right: 10px;
		float: right;
		margin-bottom: 12px;
	}
}

/******* END CHARTS *********/

.TableIncomes-Expenses {
	height: auto;
	overflow-x: auto;
}

.TableIncomes-Expenses::-webkit-scrollbar {
	width: 2px;
	/* Tamaño del scroll en vertical */
	height: 10px;
	/* Tamaño del scroll en horizontal */
}

.CardStyleRealeState {
	margin: auto;
	margin-left: 16px;
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	margin-bottom: 15px;
	height: auto;
}

.StyleViabilityComponent {
	width: 352px;
	height: auto;
	margin-top: 16px;
	margin-right: 20px;
	margin-left: 20px;
	margin-bottom: 42px;
}

.ButtonDocuments {
	height: 14px;
	margin-top: 35px;
	display: flex;
	justify-content: center;
	margin-bottom: 0;
}

.ButtonRepeatOrPass {
	display: none;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	margin-bottom: 96px;
}

.buttonRepeat {
	margin-left: 8px;
	margin-bottom: 0;
	font-size: 14px;
}

.styleHeaderTitle {
	display: grid;
	grid-template-columns: 1.25fr 0.5fr;
}

.EstimatedViability {
	display: flex;
	flex-direction: row;
	width: calc(100% - 400px);
	margin: 48px auto 81px;
}

.EstimatedViabilityB {
	display: flex;
	flex-direction: column;
	width: calc(100% - 100px);
	margin: 48px auto 81px;
}

.EstimatedViabilityParagraph {
	margin: auto;
	width: calc(100% - 20px);
	text-align: left;
}

.EstimatedViabilityParagraphB {
	margin: auto;
	width: 100%;
	text-align: center;
	font-weight: 600;
}

.EstimatedViabilityProgress {
	margin-top: 24px;
	margin-bottom: 24px;
	color: black;
	margin-right: 40px;
	align-self: center;
}

.SimulationFixed {
	display: flex;
	justify-content: center;
	// flex-direction: row;
	margin: auto;
	text-align: center;
	width: 100%;
}

.cardViabilityData {
	border-color: #02c3cd;
	width: 592px;
	margin-right: 196px;
}

.cardViabilityDataNew {
	background-color: #fafefe;
	border-color: #c0f0f3;
	width: 592px;
	margin-right: 196px;
}

.ButtonRepeatOrPassB {
	display: none;
}

.cardsViabilityOptionsC {
	.ant-card-body {
		padding: 20px;
		text-align: center;
	}
}

.cardsViabilityOptionsCMobile {
	.ant-card-body {
		padding: 18px !important;
	}
}

.cardsViabilityOptionsCTitle {
	.ant-card-body {
		padding: 10px;
		text-align: right;
	}
}

.cardsViabilityOptionsCTotal {
	.ant-card-body {
		padding: 12px 0;
		text-align: center;
	}
}

.textViabilityData {
	color: #2f4858;
	font-weight: 600;
	font-size: 16px;
	// width: 592px;
	display: flex;
	place-content: center;
	margin-top: 16px;
}

.textSimulationData {
	color: #2f4858;
	font-weight: 600;
	font-size: 16px;
	// width: 592px;
	display: flex;
	place-content: center;
	margin-top: 16px;
}

.marginEstimatedCards {
	// padding-left: 12px;
	padding-right: 12px;
}

.marginEstimatedCards1 {
	// padding-left: 12px;
	padding-right: 12px;
}

.ModalAnalista {
	position: absolute;
	right: 24px;
	bottom: 0;
	top: calc(100% - 380px);
	// .ant-modal{
	//   position: absolute;
	//   top: 600px;
	//   // bottom: 0;
	//   right: 24px;
	//   // top: null;

	// }
	.ant-modal-content {
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-confirm-btns {
		position: absolute;
		margin-top: 40px;
		right: 0px;

		// bottom: 24px;
		// right: 24px;
		// width: 53px;
		// height: 49px;
		.ant-btn-primary {
			width: 49px;
			height: 49px;
		}
	}
}

.CardTypeMortgageAjust {
	padding-right: 48px;
}

@media only screen and (max-width: 750px) {
	.marginEstimatedCards {
		padding-left: 0px;
		padding-right: 0px;
	}

	.marginEstimatedCards1 {
		padding-left: 0px;
		padding-right: 0px;
	}

	.textViabilityData {
		width: 100%;
		margin-top: 40px;
	}

	.textSimulationData {
		width: 100%;
		display: none;
	}

	.cardViabilityData {
		width: 100%;
		margin-right: 0px;
		border: none;

		.ant-card-body {
			padding: 4px;
		}
	}

	.cardTypeViabilityMobile {
		.ant-card-body {
			padding: 24px;
		}
	}

	.buttonRepeat {
		margin-left: 0px;
	}

	.CardStyleRealeState {
		.ant-card-body {
			padding: 24px;
		}
	}

	.EstimatedCards {
		margin-right: 0;
	}

	.StyleViabilityComponent {
		width: auto;
		margin-right: 8px;
		margin-left: 8px;
	}

	.ButtonDocuments {
		display: initial;
		margin-bottom: 38px;
	}

	.ButtonRepeatOrPass {
		display: flex;
		flex-direction: column;
		margin-top: 60px;
		margin-bottom: 64px;
	}

	.ButtonRepeatOrPassB {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.CardTypeMortgageAjust {
		padding: 0 0;
	}

	.buttonRepeat {
		margin-right: 0;
		margin-bottom: 8px;
	}

	.styleHeaderTitle {
		display: flex;
		flex-direction: column;
		grid-template-columns: 0;
	}

	.buttonHidden {
		display: none;
	}

	.EstimatedViability {
		flex-direction: column;
		width: auto;
		margin: 0 0 40px 0;
	}

	.EstimatedViabilityParagraph {
		width: auto;
		text-align: center;
	}

	.EstimatedViabilityProgress {
		margin-right: 0;
	}

	.SimulationFixed {
		flex-direction: column;
	}

	.textAjust {
		width: 140px;
	}

	.textAjustCenter {
		text-align: center;
	}

	.EstimatedViabilityParagraphB {
		width: 100%;
	}

	.cardAjustRates {
		flex-wrap: wrap;
	}
}

.CardStyleClientGibobs {
	margin: auto;
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	height: auto;
	// margin-top: 10px;
	width: calc(100% - 80px);
	margin-left: 40px;
}

.mortgageSimulationMobile {
	display: inline;
}

.mortgageSimulationMobile {
	display: none;
}

.TypeMortgageCard {
	height: 100%;
	background-color: #effcfc;
	border: 2px solid #01929a;
	border-radius: 4px;

	.ant-card-head-title {
		font-weight: 700;
		font-size: 16px;
		color: #ffffff;
		text-align: center;
		padding: 11px 0;
	}

	.ant-card-head {
		background-color: #01929a;
	}

	.ant-card-body {
		padding: 24px;
	}
}

.TypeMortgageCardMovile {
	height: 100%;
	background-color: #effcfc;
	border: 2px solid #01929a;
	border-radius: 4px;

	.ant-card-head-title {
		font-weight: 700;
		font-size: 16px;
		color: #ffffff;
		text-align: center;
		padding: 11px 0;
	}

	.ant-card-head {
		background-color: #01929a;
	}

	.ant-card-body {
		padding: 16px;
	}
}

.TypeMortgageCardWithoutHead {
	//width: 400px;
	// margin-left: 24px;
	height: 100%;
	background-color: #effcfc;
	border: none;

	.ant-card-head-title {
		font-weight: 700;
		font-size: 16px;
		color: #ffffff;
		text-align: center;
		padding: 11px 0;
	}

	.ant-card-head {
		background-color: white;
	}

	.ant-card-body {
		border: 2px solid #02c3cd;
		border-radius: 4px;
	}
}

.StepsSimulation {
	width: 750px;
	margin: auto;
	margin-bottom: 10px;

	.ant-steps-item-description {
		width: 100%;
		// margin-left: -50px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;

			// margin-left: 10px;
			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a;
					width: 70px;
					margin-left: -40px;
					text-align: left;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 4px solid #02c3cd;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				width: 100px;
				margin-left: -68px;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 1px solid rgba(116, 142, 160, 0.5);
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: rgba(116, 142, 160, 0.5);
					font-weight: normal;
					// width: 100px;
				}
			}
		}
	}
}

.StepsSimulationBasic {
	//width: 750px;
	margin: auto;
	margin-bottom: 48px;

	.ant-steps-item-description {
		width: 100%;
		// margin-left: -50px;
		font-size: 12px;
		text-align: center;
		margin-top: 40px;
	}

	.ant-steps-item-tail {
		width: 9px;
		// margin-left: -50px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;

			// margin-left: 10px;
			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a;
					width: 70px;
					margin-left: -40px;
					text-align: left;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 4px solid #02c3cd;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				margin-left: -70px;
				text-align: center !important;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 1px solid rgba(116, 142, 160, 0.5);
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: rgba(116, 142, 160, 0.5);
					font-weight: normal;
					// width: 100px;
				}
			}
		}
	}
}

.stepFiveOtherLanguage {
	.ant-steps-item-description {
		margin-left: -60px;
		width: 80px;
	}
}

.stepSixOtherLanguage {
	.ant-steps-item-description {
		margin-left: -60px;
		width: 80px;
	}
}

.stepSevenOtherLanguage {
	.ant-steps-item-container {
		width: 150px;
		align-items: center !important;
		align-content: center !important;
	}

	.ant-steps-item-description {
		width: 80px;
		margin-left: -60px !important;
		text-align: center !important;
		align-items: center !important;
		align-content: center !important;
	}
}

.stepOneBasic {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-tail {
		margin-left: -10px;
	}

	.ant-steps-item-description {
		margin-left: -52px !important;
		width: 60px !important;
		text-align: center !important;
	}
}

.stepTwoBasic {
	.ant-steps-item-description {
		margin-left: -47px;
		width: 50px !important;
		text-align: center !important;
	}
}

.stepTwoBasic2 {
	.ant-steps-item-description {
		margin-left: -47px !important;
		width: 50px !important;
		text-align: center !important;
	}
}

.stepThreeBasic {
	.ant-steps-item-container {
		width: 7px;
		margin-left: 7px;
	}

	.ant-steps-item-description {
		margin-left: -55px;
		width: 70px;
		text-align: center !important;
	}
}

.stepFourBasic {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-container {
		width: 80px;
		margin-left: 7px;
	}

	.ant-steps-item-description {
		margin-left: -69px;
		width: 100px;
		text-align: center !important;
	}
}

.stepFiveBasic {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-description {
		margin-left: -68px;
		width: 100px;
		text-align: center !important;
	}
}

.stepSixBasic {
	.ant-steps-item-description {
		margin-left: -72px;
		width: 100px;
	}
}

.stepSevenBasic {
	.ant-steps-item-description {
		margin-left: -70px;
		width: 100px;
	}
}

.stepTwo {
	.ant-steps-item-description {
		margin-left: -25px;
		width: 50px !important;
		text-align: left !important;
	}
}

.stepThree {
	.ant-steps-item-description {
		margin-left: -52px;
		width: 70px;
		text-align: left !important;
	}
}

.stepFour {
	.ant-steps-item-description {
		margin-left: -62px;
		width: 100px;
	}
}

.stepFive {
	.ant-steps-item-description {
		margin-left: -64px;
		width: 100px;
	}
}

.stepSix {
	.ant-steps-item-description {
		margin-left: -72px;
		width: 100px;
	}
}

.stepSeven {
	.ant-steps-item-description {
		margin-left: -70px;
		width: 100px;
	}
}

.scrollStepTypeMortgages {
	margin: 16px 24px;
	width: calc(100% - 48px);

	// overflow-x: scroll;
	.ant-steps-item-icon {
		width: 30px;
		height: 30px;
	}
}

.scrollStepTypeMortgages::-webkit-scrollbar {
	width: 2px;
	/* Tamaño del scroll en vertical */
	height: 8px;
	/* Tamaño del scroll en horizontal */
}

.scrollStepTypeMortgages::-webkit-scrollbar-track {
	background: rgba(116, 142, 160, 0.1);
}

.scrollStepTypeMortgages::-webkit-scrollbar-thumb {
	background: rgba(116, 142, 160, 0.2);
	// border-radius: 6px;
	// border: 3px solid white;
}

.newSubmenusTabs {
	display: flex;
	background-color: #effcfc;
	margin-top: 24px;
	margin-left: 40px;
	border-radius: 5px;
	margin-bottom: 24px;
	border-bottom: none;
}

.operationsClientsCard {
	width: calc(100% - 60px);
	margin-left: 28px !important;
	margin-right: 0px;
	height: auto;
	margin-bottom: 100px;
}

.scroolHorizontal::-webkit-scrollbar {
	width: 8px;
	/* Tamaño del scroll en vertical */
	height: 8px;
	/* Tamaño del scroll en horizontal */
	display: none;
	/* Ocultar scroll */
}

@media only screen and (max-width: 360px) and (min-width: 0) {
	.CardStyleClientGibobs {
		width: calc(100% - 32px);
		margin-left: 16px;

		.ant-card-body {
			padding: 24px;
		}
	}

	.newSubmenusTabs {
		margin-left: 16px;
	}

	.operationsClientsCard {
		width: auto !important;
		margin-left: 4px !important;
		margin-right: 0px;
	}

	.cardViabilityData {
		width: 100%;
		margin-right: 0px;
		border: none;

		.ant-card-body {
			padding: 4px;
		}
	}

	.cardTypeViabilityMobile {
		.ant-card-body {
			padding: 24px;
		}
	}

	.StyleCards {
		margin-top: 16px;
	}

	.EstimatedViability {
		width: auto;
		margin: 48px auto 81px;
	}

	.mortgageSimulationMobile {
		display: inline;
		width: 100%;
	}

	.mortgageSimulation {
		display: none;
	}

	.breadCrumbViability {
		margin-left: 16px;
	}

	.scrollStepTypeMortgages {
		overflow-x: scroll;
	}
}

@media only screen and (min-width: 360px) and (max-width: 768px) {
	.CardStyleClientGibobs {
		width: calc(100% - 32px);
		margin-left: 16px;

		.ant-card-body {
			padding: 24px;
		}
	}

	.operationsClientsCard {
		width: auto;
		margin-left: 4px !important;
		margin-right: 0px;
	}

	.newSubmenusTabs {
		margin-left: 16px;
	}

	.breadCrumbViability {
		margin-left: 16px;
	}

	.StyleCards {
		margin-top: 16px;
	}

	.EstimatedViability {
		width: auto;
		margin: 48px auto 81px;
	}

	.mortgageSimulationMobile {
		display: inline;
		width: 100%;
	}

	.mortgageSimulation {
		display: none;
	}

	.cardViabilityData {
		width: 100%;
		margin-right: 0px;
		border: none;

		.ant-card-body {
			padding: 4px;
		}
	}

	.cardTypeViabilityMobile {
		.ant-card-body {
			padding: 24px;
		}
	}

	.scrollStepTypeMortgages {
		overflow-x: scroll;
	}
}

@media only screen and (max-width: 1200px) {
	.mortgageSimulationMobile {
		display: inline;
		width: 100%;
	}

	.mortgageSimulation {
		display: none;
	}

	// .cardViabilityData{
	//   border: none;
	// }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.buttonHidden {
		display: none;
	}

	.mortgageSimulationMobile {
		display: inline;
		width: 100%;
	}

	.CardStyleClientGibobs {
		width: calc(100% - 48px);
		margin-left: 24px;

		.ant-card-body {
			padding: 24px;
		}
	}

	.operationsClientsCard {
		width: auto;
		margin-left: 12px !important;
		margin-right: 0px;
	}

	.newSubmenusTabs {
		margin-left: 24px;
	}

	.StyleCards {
		margin-top: 16px;
	}

	.EstimatedViability {
		width: auto;
		margin: 48px auto 81px;
	}

	.breadCrumbViability {
		margin-left: 24px;
	}

	.scrollStepTypeMortgages {
		overflow-x: scroll;
	}

	.ButtonRepeatOrPassB {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}

.progressLoanToValue {
	.ant-progress-inner {
		width: 100px;
		height: 100px;
	}

	.ant-progress-text {
		font-size: 16px;
		color: #2f4858;
	}
}

.gcard4 {
	border: 1px solid #c0f0f3;
	margin-bottom: 20px;
	background-color: #effcfc;
	margin-left: 40px;
	height: auto;
	width: calc(100% - 80px);

	.ant-card-body {
		padding: 18px;
	}
}

/******* DASHBOARD *********/

.dashBoardRealeState {
	margin-left: 30px;
	margin-right: 30px;
	width: 1186px;

	.box {
		width: 264px;
		height: 264px;
		margin-bottom: 40px;
		margin-right: 40px;
		margin-left: 0;
		cursor: 'pointer';

		&.box-double {
			width: 794px;

			.grid {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}
		}

		.box-text {
			font-weight: bold;
			font-size: 64px;
			width: 216px;
			height: 64px;
			margin-top: 32px;
			margin-bottom: 32px;
		}

		.box-subtext {
			font-weight: bold;
			font-size: 14px;
			color: '#2F4858';
			width: 216px;
			height: 18px;
		}
	}

	@media only screen and (max-width: 750px) {
		width: 90%;
		margin-left: 5%;
		margin-right: 5%;

		.box {
			width: 100%;

			&.box-double {
				width: 100%;

				.grid {
					display: inherit;
				}
			}

			.box-text {
				width: 100%;
			}

			.box-subtext {
				width: 100%;
			}
		}
	}
}

.ant-table-wrapper {
	position: sticky;
}

/******* END DASHBOARD *********/

.scrollListDocuments {
	width: 800px;
	overflow-x: scroll;
}

.scrollListDocuments::-webkit-scrollbar {
	width: 2px;
	/* Tamaño del scroll en vertical */
	height: 8px;
	/* Tamaño del scroll en horizontal */
}

.scrollViability {
	width: 100%;
	overflow-x: scroll;
}

.scrollViability::-webkit-scrollbar {
	width: 2px;
	/* Tamaño del scroll en vertical */
	height: 8px;
	/* Tamaño del scroll en horizontal */
}

.progressLine {
	.ant-progress-steps-item {
		background: #c0f0f3;
	}

	.ant-progress-text {
		color: transparent;
	}
}

.tabsProfileHeadline {
	.ant-tabs-nav {
		align-self: center;
	}
}

.stepsLoanPromoter {
	.ant-steps-item-wait .ant-steps-item-icon {
		background: #c0daee;
	}

	.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
		background: #124887;
		border-color: #124887;
	}

	.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
		display: inline;
		color: #ffffff;
		font-weight: 600;
	}
}

.labelFormat {
	.ant-col {
		.ant-form-item-label {
			font-size: 14px;
		}
	}
}

.ant-switch-checked .SwitchColorPromoter {
	background: #124887;
}

// .SwitchColorPromoter{
//   background: #124887;
// }

.mobileStepsInmo {
	.ant-steps-item-description {
		width: 60px;
	}
}

.StepsAppraisalMovile {
	.ant-steps-item-icon {
		width: 32px !important;
		height: 32px !important;
		background: white;
	}

	.ant-steps-item-description {
		width: 60px;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			.ant-steps-icon {
				display: inline-flex;
			}
		}
	}

	.ant-steps-item-tail::after {
		margin-left: 4px;
		margin-top: 15px;
		width: 2px;
		height: 100%;
	}
}

.tabsProfileHeadline {
	.ant-tabs-nav {
		align-self: center;
	}
}

.progressAnalitic {
	.ant-progress-text {
		display: none;
	}
}

.create-products {
	height: 'auto';
	margin-bottom: '100px';

	.product-card {
		margin: auto;
		width: 100%;
		align-items: center;
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
		height: 100%;
		overflow: hidden;
		border-radius: 4px;

		.title-productos {
			font-weight: 700;
			font-size: 16px;
			color: #2f4858;
		}

		.sub-title-productos {
			font-weight: 600;
			font-size: 14px;
			color: #02c3cd;
			margin-top: 8px;
			min-height: 50px;
		}

		.description-product {
			font-weight: 400;
			font-size: 12px;
			color: #748ea0;
			margin-top: 16px;
			margin-bottom: 25px;
			//height: 150px;
		}

		.card-bottom {
			width: 100%;
			height: 24px;
			border-top: none;
		}

		.ant-card-actions {
			border-top: none;
		}
	}

	// .rotateImgEco {
	//   transform: rotate(270deg);
	//   -ms-transform: rotate(270deg);
	//   -moz-transform: rotate(270deg);
	//   -webkit-transform: rotate(270deg);
	//   -o-transform: rotate(270deg);
	// }
}

.init-card-apps {
	margin: auto;
	width: 100%;
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	height: 100%;
	overflow: hidden;
	background-image: url('./assets/init/faldonm.png');
	border-radius: 4px;
	background-size: cover;

	.ant-card-body {
		padding: 24px;
	}

	.title-init-card {
		font-weight: 600;
		font-size: 14px;
		color: #2f4858;
	}

	.sub-title-productos {
		font-weight: 600;
		font-size: 14px;
		color: #02c3cd;
		margin-top: 24px;
		min-height: 50px;
	}

	.description-product {
		font-weight: 400;
		font-size: 12px;
		color: #748ea0;
		margin-top: 24px;
		margin-bottom: 25px;
		//height: 150px;
	}

	.card-bottom {
		width: 100%;
		height: 24px;
		border-top: none;
	}

	.ant-card-actions {
		border-top: none;
	}
}

.init-card-apps-desktop {
	width: calc(100% - 50px);
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	max-height: 72px;
	overflow: hidden;
	background-image: url('./assets/init/background.png');
	background-size: cover;
	border-radius: 4px;
	border-bottom-left-radius: 7px !important;
	border-bottom-right-radius: 7px !important;

	margin-left: 40px;

	.ant-card-body {
		padding: 24px;
		height: 72px;
	}

	.title-init-card {
		font-weight: 600;
		font-size: 14px;
		color: #2f4858;
	}

	.sub-title-productos {
		font-weight: 600;
		font-size: 14px;
		color: #02c3cd;
		margin-top: 24px;
		min-height: 50px;
	}

	.description-product {
		font-weight: 400;
		font-size: 12px;
		color: #748ea0;
		margin-top: 24px;
		margin-bottom: 25px;
		//height: 150px;
	}

	.card-bottom {
		width: 100%;
		height: 24px;
		border-top: none;
	}

	.ant-card-actions {
		border-top: none;
	}
}

.init-card-apps-mobile-background {
	width: calc(100% - 50px);
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	max-height: 72px;
	overflow: hidden;
	background-image: url('./assets/init/banner_apps.png');
	background-size: cover;
	border-radius: 4px;
	border-bottom-left-radius: 7px !important;
	border-bottom-right-radius: 7px !important;

	margin-left: 40px;

	.ant-card-body {
		padding: 24px;
		height: 72px;
	}

	.title-init-card {
		font-weight: 600;
		font-size: 14px;
		color: #2f4858;
	}

	.sub-title-productos {
		font-weight: 600;
		font-size: 14px;
		color: #02c3cd;
		margin-top: 24px;
		min-height: 50px;
	}

	.description-product {
		font-weight: 400;
		font-size: 12px;
		color: #748ea0;
		margin-top: 24px;
		margin-bottom: 25px;
		//height: 150px;
	}

	.card-bottom {
		width: 100%;
		height: 24px;
		border-top: none;
	}

	.ant-card-actions {
		border-top: none;
	}
}

.progress-init {
	.ant-progress-inner {
		background-color: #c0f0f3;
		width: 100%;
		stroke: '#02C3CD';
	}

	.ant-progress-text {
		visibility: hidden;
	}
}

.textCopy:hover {
	cursor: pointer;
}

.productSteps {
	.ant-steps-item-finish .ant-steps-item-icon {
		background: white;
	}

	.ant-steps-item-finish .ant-steps-item-tail::after {
		margin-top: 5px;
	}
}

.ModalCommonNewPage {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
		position: relative;
		top: 18px;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 24px;
		padding-top: 10px;
		padding-bottom: 38px;
		padding-right: 40px;
		background-color: #fafefe;
	}

	.ant-modal-body {
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 0px;
		background-color: #fafefe;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 24px;
		padding-bottom: 24px;
		border-top: none;
		background-color: #fafefe;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 22px;
		padding-right: 38px;
	}
}

.ModalCommonNewPageCert {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #02c3cd;
	}

	.ant-modal-header {
		border-bottom: none;
		padding: 24px;
		background-color: #fafefe;
	}

	.ant-modal-body {
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 0px;
		background-color: #fafefe;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 24px;
		padding-bottom: 24px;
		border-top: none;
		background-color: #fafefe;
	}

	.ant-modal-close-x {
		color: #2f4858;
	}
}

.CardStyleRealEsteBuySell {
	background-color: #effcfc;
	border-radius: 4px;
	border-color: #effcfc;
}

.infinity {
	width: 120px;
	height: 60px;

	// position: relative;
	div,
	span {
		position: absolute;
	}

	div {
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		animation: rotate 6.9s linear infinite;

		span {
			left: -8px;
			top: 50%;
			margin: -8px 0 0 0;
			width: 16px;
			height: 16px;
			display: block;
			background: #8c6ff0;
			box-shadow: 2px 2px 8px rgba(#8c6ff0, 0.09);
			border-radius: 50%;
			transform: rotate(90deg);
			animation: move 6.9s linear infinite;

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				border-radius: 50%;
				width: 14px;
				height: 14px;
				background: inherit;
				top: 50%;
				left: 50%;
				margin: -7px 0 0 -7px;
				box-shadow: inherit;
			}

			&:before {
				animation: drop1 0.8s linear infinite;
			}

			&:after {
				animation: drop2 0.8s linear infinite 0.4s;
			}
		}

		&:nth-child(2) {
			animation-delay: -2.3s;

			span {
				animation-delay: -2.3s;
			}
		}

		&:nth-child(3) {
			animation-delay: -4.6s;

			span {
				animation-delay: -4.6s;
			}
		}
	}
}

.infinityChrome {
	width: 128px;
	height: 60px;

	div {
		position: absolute;
		width: 16px;
		height: 16px;
		background: #8c6ff0;
		box-shadow: 2px 2px 8px rgba(#8c6ff0, 0.09);
		border-radius: 50%;
		animation: moveSvg 6.9s linear infinite;
		-webkit-filter: url(#goo);
		filter: url(#goo);
		transform: scaleX(-1);
		offset-path: path(
			'M64.3636364,29.4064278 C77.8909091,43.5203348 84.4363636,56 98.5454545,56 C112.654545,56 124,44.4117395 124,30.0006975 C124,15.5896556 112.654545,3.85282763 98.5454545,4.00139508 C84.4363636,4.14996252 79.2,14.6982509 66.4,29.4064278 C53.4545455,42.4803627 43.5636364,56 29.4545455,56 C15.3454545,56 4,44.4117395 4,30.0006975 C4,15.5896556 15.3454545,4.00139508 29.4545455,4.00139508 C43.5636364,4.00139508 53.1636364,17.8181672 64.3636364,29.4064278 Z'
		);

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			border-radius: 50%;
			width: 14px;
			height: 14px;
			background: inherit;
			top: 50%;
			left: 50%;
			margin: -7px 0 0 -7px;
			box-shadow: inherit;
		}

		&:before {
			animation: drop1 0.8s linear infinite;
		}

		&:after {
			animation: drop2 0.8s linear infinite 0.4s;
		}

		&:nth-child(2) {
			animation-delay: -2.3s;
		}

		&:nth-child(3) {
			animation-delay: -4.6s;
		}
	}
}

@keyframes moveSvg {
	0% {
		offset-distance: 0%;
	}

	25% {
		background: #5628ee;
	}

	75% {
		background: #23c4f8;
	}

	100% {
		offset-distance: 100%;
	}
}

@keyframes rotate {
	50% {
		transform: rotate(360deg);
		margin-left: 0;
	}

	50.0001%,
	100% {
		margin-left: -60px;
	}
}

@keyframes move {
	0%,
	50% {
		left: -8px;
	}

	25% {
		background: #5628ee;
	}

	75% {
		background: #23c4f8;
	}

	50.0001%,
	100% {
		left: auto;
		right: -8px;
	}
}

@keyframes drop1 {
	100% {
		transform: translate(32px, 8px) scale(0);
	}
}

@keyframes drop2 {
	0% {
		transform: translate(0, 0) scale(0.9);
	}

	100% {
		transform: translate(32px, -8px) scale(0);
	}
}

.gcardTable {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-upload-list-item-error,
	.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
	.ant-upload-list-item-error .ant-upload-list-item-name {
		color: #02c3cd;
	}

	.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
		color: #02c3cd;
	}
}

.buttonOperations {
	background-color: #effcfc;
	color: #02c3cd;
	font-weight: 400;
	font-size: 14px;
	border: none;
	border-bottom: none;
}

.buttonOperations:hover {
	background: none;
}

.gcard-pay {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-card-body {
		padding: 24px;
	}

	.ant-tooltip-placement-top,
	.ant-tooltip-placement-topLeft,
	.ant-tooltip-placement-topRight {
		visibility: hidden;
	}
}

.ant-notification {
	position: fixed !important;
	top: 40px !important;
	width: 10% !important;
}

.modal-movile-hidden {
	width: auto;

	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
		background-color: #ffffff;
		border-radius: 4px;
	}

	.ant-modal-content {
		position: fixed;
		bottom: 96px;
		left: 10px;
		right: 10px;
		background-color: #ffffff;
		border-radius: 4px;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 40px;
		padding-top: 40px;
		padding-bottom: 38px;
		padding-right: 40px;
		background-color: #ffffff;
		border-radius: 4px;
	}

	.ant-modal-body {
		padding: 32px;
		padding-top: 0px;
		background-color: #ffffff;
		margin-bottom: -20px;
		border-radius: 4px;
	}

	.ant-modal-footer {
		visibility: hidden;
		background-color: #ffffff;
	}
}

.modal-movile-hidden-analyst {
	width: auto;

	.ant-modal-content {
		border-radius: 8px;
	}

	.ant-modal-body {
		padding-top: 12px;
		margin-bottom: -50px;
		background-color: #ffffff;
		border-radius: 8px;
		padding: 0px;
	}

	.ant-modal-footer {
		visibility: hidden;
		background-color: #ffffff;
	}
}

.title-card-mobile {
	font-size: 16px;
	font-weight: 600;
	color: #2f4858;
	font-family: 'Inter';
}

.modal-movile-hidden-data-basic {
	width: auto;

	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-content {
		position: fixed;
		bottom: 150px;
		left: 10px;
		right: 8px;
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 24px;
		padding-top: 24px;
		padding-bottom: 24px;
		padding-right: 24px;
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-body {
		padding: 24px;
		padding-top: 0px;
		background-color: #effcfc;
		margin-bottom: -40px;
		border-radius: 4px;
	}

	.ant-modal-footer {
		visibility: hidden;
		background-color: #effcfc;
	}
}

.modal-desktop-hidden {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-content {
		width: 328px;
		position: fixed;
		bottom: 96px;
		right: 32px;
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 38px;
		padding-right: 32px;
		background-color: #effcfc;
		border-radius: 4px;
	}

	.ant-modal-body {
		padding: 32px;
		padding-top: 0px;
		background-color: #effcfc;
		margin-bottom: -50px;
		border-radius: 4px;
		margin-top: -10px;
	}

	.ant-modal-footer {
		visibility: hidden;
		background-color: #effcfc;
	}
}

.mobileComponentScroll {
	margin-top: 50px;
	height: 100vh;
	overflow-y: scroll;
	// position: relative;
}

.mobileComponentScroll::-webkit-scrollbar {
	width: 2px;
	background-color: transparent;
}

.mobileComponentScroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	height: 50px;
	background-color: transparent;
}

.modal-documents-movile-ofert {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-content {
		position: fixed;
		bottom: 0px;
		left: 0px;
		right: 0px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.ant-modal-header {
		border-bottom: none;
		padding: 24px;
		border-radius: 8px;
	}

	.ant-modal-body {
		padding: 24px;
		padding-top: 15px;
		margin-bottom: -20px;
	}

	.ant-modal-close-x {
		margin-top: 10px;
		color: #02c3cd;
	}

	.ant-modal-footer {
		text-align: center;
		width: 100%;
		display: flex;
	}
}

.gb-card-products {
	.ant-card-body {
		padding: 12px;
	}
}

.stagesInmo {
	.ant-card-body {
		padding: 16px !important;
	}
}

.bottonInmoDni {
	.ant-upload-select-text {
		width: 100%;
	}

	.ant-upload-list-item-error,
	.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
	.ant-upload-list-item-error .ant-upload-list-item-name {
		color: #02c3cd;
	}

	.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
		color: #02c3cd;
	}

	.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
		border: none;
	}

	.ant-tooltip-placement-top,
	.ant-tooltip-placement-topLeft,
	.ant-tooltip-placement-topRight {
		visibility: hidden;
	}
}

.ModalCommonInmo {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #f1f5f5;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-top: 32px;
		padding-left: 32px;
		padding-right: 32px;
	}

	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		margin-top: -30px;
	}

	.ant-modal-footer {
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 32px;
		display: flex;
		flex-direction: column-reverse;
		border-top: none;
		margin-top: 0px;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding: 16px;
	}
}

.gb-form-profile-headlines-mobile {
	.ant-form-item {
		margin-bottom: 16px;

		.ant-form-item-label {
			line-height: 16px;

			label {
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
				//min-height: 40px;
			}
		}

		.ant-input-affix-wrapper {
			height: 40px;
			color: #748ea0;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			border-color: #c0daee;
			border-radius: 4px;

			input {
				height: 30px;
			}
		}

		.ant-input-affix-wrapper {
			height: 40px;
			color: #748ea0;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			border-color: #c0daee;
			border-radius: 4px;

			input {
				height: 30px;
			}
		}

		.ant-form-item-control {
			.ant-input-affix-wrapper {
				input {
					height: 30px;
				}
			}

			input {
				height: 40px;
				color: #2f4858;
				font-size: 14px;
				line-height: 20px;
				width: 100%;
			}

			.ant-radio-wrapper {
				color: #748ea0;
				font-size: 14px;
				font-weight: 400;
			}

			.ant-checkbox-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}

			.ant-radio-wrapper-checked {
				color: #2f4858;
				font-size: 14px;
				font-weight: 400;
			}

			.ant-form-item-explain-error {
				font-size: 12px;
				margin-top: 8px;
			}

			.ant-form-item-control-input-content {
				textarea {
					color: #748ea0;
					font-size: 14px !important;
				}
			}

			.ant-select-selector {
				height: 40px;
				width: 100%;

				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
					color: #2f4858;
				}

				.ant-select-selection-placeholder {
					height: 40px;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}

.modal-common-mobile {
	.ant-modal-content {
		border-radius: 4px !important;
		border-top-left-radius: 4px !important;
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 8px;
		padding-right: 32px;
		border-top-left-radius: 4px !important;
		border-top-right-radius: 4px !important;
	}

	.ant-modal-body {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-right: 32px;
	}

	.ant-modal-footer {
		padding-right: 32px;
		padding-left: 32px;
		padding-bottom: 32px;
		border-top: none;
		display: flex;
		place-content: center !important;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 16px;
		padding-right: 38px;
	}
}

.StepsAppraisal4 {
	.ant-steps-item-description {
		margin-left: 0;
		margin-top: 12px;
	}

	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
		background: white;
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #02c3cd;
				}
			}
		}

		.ant-steps-item-icon {
			background: white;

			.ant-steps-icon {
				display: none;
			}
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #02c3cd;
				}
			}
		}

		.ant-steps-item-icon {
			border-color: #52c41a;

			.ant-steps-icon {
				top: -1.5px;
				display: inline;
				color: #52c41a;
			}
		}
	}
}

.gb-backgroud-friend-mobile {
	background-image: url('./assets/init/backgroundFriend.png');
	background-size: cover;
}

.ModalCommonSmallNoButtons {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 40px;
		padding-top: 40px;
		padding-bottom: 0px;
		padding-right: 40px;
	}

	.ant-modal-body {
		padding-left: 40px !important;
		padding-right: 40px !important;
		padding-top: 0px !important;
		padding-bottom: 40px !important;
		font-size: 12px;
		// padding-bottom: -10px;
	}

	.ant-modal-footer {
		visibility: hidden;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 12px;
		padding-right: 28px;
	}
}

.gb-accordion-amigobs {
	.ant-collapse-content > .ant-collapse-content-box {
		padding: 24px !important;
	}

	.ant-steps-icon {
		background-color: white;
		display: inline;
	}

	.ant-steps-item-finish .ant-steps-item-icon {
		border-width: 2.5px;
	}

	.ant-steps-item-wait {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: #c0daee;
					font-size: 16px;
					margin-top: 0;
				}
			}
		}
	}
}

.StepsSimulationBasicCertificate {
	margin: auto;
	margin-bottom: -38px;

	.ant-steps-item-process
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-description {
		width: 100% !important;
	}

	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-description {
		width: 100% !important;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;
			width: 24px;
			height: 24px;

			// margin-left: 10px;
			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
				position: relative;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-active {
			.ant-steps-item-container {
				.ant-steps-item-tail {
					display: contents;
					width: 30px;
				}
			}
		}
	}

	.ant-steps-item-tail::after {
		min-height: 0px;
		margin-left: 3px;
		margin-top: 14px;
	}
}

.gb-tabs-mobile {
	.ant-tabs > .ant-tabs-nav {
		align-self: center !important;
	}

	.ant-tabs > div > .ant-tabs-nav {
		align-self: center !important;
	}

	.ant-tabs
		> .ant-tabs-nav
		.ant-tabs-nav-wrap
		> .ant-tabs
		> div
		> .ant-tabs-nav
		.ant-tabs-nav-wrap {
		place-content: center;
	}
}

.select-indivual-inmo {
	.ant-select-selector {
		height: 40px !important;
		width: 100%;

		.ant-select-selection-item {
			align-self: center;
			font-size: 14px;
			color: #748ea0;
		}

		.ant-select-selection-placeholder {
			height: 40px !important;
			font-size: 14px;
			padding: 4px 0;
		}
	}
}

.input-subrogation {
	.ant-input-number-input {
		height: 30px !important;
	}
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
	display: none !important;
}

.cardMkt {
	.ant-card-body {
		padding: 16px !important;
	}

	&:hover {
		opacity: 1 !important;
	}
}

.offersTable {
	margin-top: 16px;

	.ant-table {
		color: #2f4858;
		font-weight: 600;

		&-bordered > .ant-table-container {
			border: 1px solid #d9d9d9;
			border-top: none;

			& > .ant-table-content > table > thead > tr > th:nth-child(odd) {
				background-color: #ecf7fc;
			}
		}

		&-cell {
			font-size: 14px;

			&:nth-child(odd) {
				background-color: #ecf7fc;
			}
		}
	}

	::-webkit-scrollbar {
		height: 8px;
		background-color: #f1f7f8;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #c0daee;
	}
}

.ant-input-number-suffix {
	display: none !important;
}

@primary-color: #02C3CD;@layout-body-background: #FAFEFE;@select-item-selected: white;